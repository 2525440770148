export const urlRegexp = /(^#.+)|(^(tel):.+)|((https?|chrome|mailto):\/{0,2}[^\s$.?#].[^\s]*$)/
export const shortcutUrlRegexp = /^[{](result_url|scorecard_url])[}](\/|\/[^\s$.?#].[^\s]*)?$/

export const DEFAULT_FONTS = {
	Arial: 'Arial',
	Helvetica: 'Helvetica',
	'Times New Roman': 'Times New Roman',
	Times: 'Times',
	'Courier New': 'Courier New',
	Courier: 'Courier',
	Verdana: 'Verdana',
	Georgia: 'Georgia',
	Palatino: 'Palatino',
	Garamond: 'Garamond',
	Bookman: 'Bookman',
	'Comic Sans MS': 'Comic Sans MS',
	'Trebuchet MS': 'Trebuchet MS',
	'Arial Black': 'Arial Black',
	Impact: 'Impact',
}

export const FONT_STATUS = {
	LOADING: 1,
	LOADED: 2,
}

export const COLORS = Object.freeze({
	PRIMARY: '#1A78FE',
	CHART_DEFAULT: '#D8D8D8',
})

export const PRIMARY_COLOR_NAME = 'blue'

export const BROWSERS_VERSION = {
	Chrome: '64',
	Safari: '11',
	Firefox: '67',
	Opera: '50',
	'Microsoft Edge': '80',
}

export const AFFILIATE_APPLICATION_LINK = 'https://affiliateapplication.scoreapp.com/'

export const DEFAULT_CURRENCY = 'GBP'
